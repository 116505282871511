var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: _vm.width,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        destroyOnClose: true,
        cancelText: "关闭",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "父级节点",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("j-tree-select", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["pid", _vm.validatorRules.pid],
                        expression: "['pid', validatorRules.pid]",
                      },
                    ],
                    ref: "treeSelect",
                    attrs: {
                      placeholder: "请选择父级节点",
                      dict: "sys_category,name,id",
                      pidField: "pid",
                      pidValue: "0",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "分类名称",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["name", _vm.validatorRules.name],
                        expression: "[ 'name', validatorRules.name]",
                      },
                    ],
                    attrs: { placeholder: "请输入分类名称" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }